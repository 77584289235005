import React, { Fragment } from 'react';
import logo from './Armstrong_Background.gif';
import './App.css';
import Login from './components/login'
import useToken from './components/useToken';
import DownloadsList from './components/downloads';

const guideUrl = `PFX_Select_Installation_Guide.pdf`;

function App() {
  const { token, setToken } = useToken();

  return <Fragment>
    <div className="App">
      <header className="App-header">
        <a href="https://armstrongfluidtechnology.com"><img src={logo} alt="logo" /></a>
        {token ? <DownloadsList /> : <Login setToken={setToken} />}
        <span>
          <a href="https://register.pfxselect.com/#/privacypolicy" target='_blank' rel='noreferrer'>Armstrong Privacy Policy</a>
          <span> | </span>
          <a href="https://register.pfxselect.com/#/termsconditions" target='_blank' rel='noreferrer'>Armstrong PFX Select Terms &amp; Conditions</a>
          <span> | </span>
          <a href={guideUrl} target='_blank' rel='noreferrer'>Armstrong PFX Select Registration Guide (PDF)</a>
        </span>
     </header>
    </div>
  </Fragment>
}

export default App;
