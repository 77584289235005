import React, { Component } from 'react'; 
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { API_BASE_URL } from '../config';
import PersonIcon from '@mui/icons-material/Person';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { saveAs } from 'file-saver';
import Paper from '@mui/material/Paper';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

class DownloadsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fetched: false,
            list: [],
            downloading: false,
        }

    };

    componentDidMount() {
        const token = sessionStorage.getItem('token');
        if (!token) {
            throw new Error("Not Authenticated");
        }
        console.log("this is 1");
        console.log(`${API_BASE_URL}/Armstrong/Downloads`);
        const request = new Request(`${API_BASE_URL}/Armstrong/Downloads`, {
            method: 'GET',
            headers: new Headers({ Authorization: `Bearer ${token}`, Accept: 'application/json' }),
        });
        fetch(request)
        .then(response => response.json())
        .then(data => this.setState({ fetched: true, list: data }))
        .catch(err => this.setState({ fetched: false, list: [] }));
    }


    render() {
        let installs = [];
        this.state.list.forEach(x => { 
            if (x.type === "Install") {
                x.recv = 0;
                x.total = 0;
                installs.push(x);
            }
        });
        return (
            <Box
                sx={{
                    // display: 'flex',
                    // flexWrap: 'wrap',
                    '& > :not(style)': {
                        m: 1,
                        p: 2,
                    },
                }}>
                <Paper elevation={1}>
                    <h2>Armstrong PFX Select Downloads</h2>
                </Paper>
                {installs.length > 0 ?
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}>
                        Installer files
                    </AccordionSummary>
                    <AccordionDetails>
                        <Paper elevation={3}>
                            <List>
                                {installs.map(item => {
                                    return (
                                        <ListItem key={item.id}>
                                            <ListItemButton>
                                                <ListItemIcon>{item.icon === "PersonIcon" ? <PersonIcon /> : <GroupAddIcon />}
                                                </ListItemIcon>
                                                <a href={"https://hexselector.com/Armstrong-Setup-MultiUser.exe"}>{item.description}</a>
                                            </ListItemButton>
                                            
                                        </ListItem>
                                    )})}
                            </List>
                        </Paper>
                    </AccordionDetails>
                </Accordion> : ''}
                
            </Box>
        );
    }
}

export default DownloadsList;